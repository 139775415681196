import { Select } from 'antd';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeCustomer } from 'src/store/auth/reducer';
import { UserResponse } from 'src/types';
import { useLocation, useNavigate } from 'react-router-dom';

interface CompanySelectorProps {
  className?: string;
  customerLabel: string;
}

const CompanySelector: FC<CompanySelectorProps> = ({
  className = '',
  customerLabel,
}) => {
  const user: UserResponse = useSelector(
    (globalState: any) => globalState.auth.user
  );

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const updateUserCompany = async (uuid: string) => {
    if (
      pathname.startsWith('/requests/') &&
      !pathname.startsWith('/requests/create')
    ) {
      navigate('/dashboard');
    }

    dispatch(changeCustomer(uuid));
  };

  if (user?.contact?.customers?.length === 1) {
    return null;
  }

  return (
    <Select
      size="large"
      className={`header__select !rounded-md ${className}`}
      defaultValue={user?.contact?.customer?.uuid}
      onChange={(e) => {
        updateUserCompany(e);
      }}
    >
      {user?.contact?.customers?.map((el: any) => (
        <Select.Option key={el.uuid} value={el.uuid}>
          {el[customerLabel]}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CompanySelector;
