import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import { BsInputCursorText } from 'react-icons/bs';
import { TbMessage2 } from 'react-icons/tb';
import { Button, Form, Tabs, Tag, Typography } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Box from 'src/components/Box';
import Comment from 'src/components/Comment';
import { FormBuilderData } from 'src/types/formBuilder';
import {
  convertMinToHrsMin,
  getRequestStatusLabel,
  getStatusColor,
  parseDateToMMMDD,
} from 'src/utils/stringHelpers';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import RequestForm from './RequestForm';
import FeedbackModal from 'src/components/FeedbackModal';

const { Text } = Typography;

interface RequestViewProps {
  request: Record<string, any>;
  formBuilder: FormBuilderData;
  resetFormTrigger?: number;
  onUpdate: (values: any) => Promise<Record<string, any>>;
}

const RequestView: FC<RequestViewProps> = ({
  request,
  formBuilder,
  resetFormTrigger = 0,
  onUpdate,
}) => {
  const [form] = Form.useForm();
  const [tabKey, setTabKey] = useState('2');
  const [saving, setSaving] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const navigate = useNavigate();

  const onWrapUpdate = async (values: any) => {
    setSaving(true);

    try {
      await onUpdate(values);
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    if (resetFormTrigger > 0) {
      form.resetFields();
    }
  }, [form, resetFormTrigger]);

  return (
    <div>
      <FeedbackModal
        visible={openFeedbackModal}
        loading={saving}
        onClose={() => {
          setOpenFeedbackModal(false);
        }}
        onConfirm={async (values: any) => {
          values.approval_status = 'rejected';

          await onWrapUpdate(values);

          setOpenFeedbackModal(false);
        }}
      />

      <div className="mb-2 flex items-center justify-between">
        <Button
          type="link"
          size="large"
          icon={<IoArrowBack />}
          style={{ paddingLeft: 0 }}
          onClick={() => {
            navigate('/dashboard');
          }}
        >
          Go back
        </Button>

        <Link
          to={`/requests/${request.uuid}/duplicate`}
          className="mr-1 text-[#0044CC]"
        >
          <Button>Duplicate</Button>
        </Link>
      </div>

      <Box>
        <Tabs
          activeKey={tabKey}
          size="small"
          className={`tab-fullscreen tab-secondary tab-compact${
            tabKey === '2' ? ' tab-no-border' : ''
          }`}
          onChange={setTabKey}
        >
          <Tabs.TabPane
            tab={
              <span className="flex items-center gap-2">
                <BsInputCursorText /> Brief
              </span>
            }
            key="1"
          >
            <RequestForm
              form={form}
              request={request}
              formBuilder={formBuilder}
              disableForm
            />
          </Tabs.TabPane>

          <Tabs.TabPane
            tab={
              <span className="flex items-center gap-2">
                <TbMessage2 /> Messages
              </span>
            }
            key="2"
          >
            <div className="mb-5 rounded-md border border-gray-200 px-3 py-2">
              <div className="mb-3 flex items-center justify-between">
                <Text type="secondary" strong>
                  PROJECT STARTED
                </Text>

                <Text type="secondary" className="!text-xs">
                  by {request.requester?.name ?? request.creator.name} at{' '}
                  {dayjs(request.created_at).format('MMM DD, YYYY - hh:mm a')}
                </Text>
              </div>

              <div className="mb-3 font-bold">{request.subject}</div>

              <div className="flex flex-wrap">
                <div className="flex-1 basis-1/2 sm:basis-auto">
                  <Text type="secondary">Hours estimated</Text>

                  <div className="text-xl">
                    {request.estimated_min || request.estimated_max
                      ? `${convertMinToHrsMin(
                          request.estimated_min ?? 0
                        )} - ${convertMinToHrsMin(
                          request.estimated_max ?? 0
                        )} hours`
                      : '-'}
                  </div>
                </div>

                <div className="flex-1 basis-1/2 sm:basis-auto">
                  {request.time_spent ? (
                    <>
                      <Text type="secondary">Total time spent</Text>

                      <div className="text-xl">
                        {convertMinToHrsMin(request.time_spent)} hours
                      </div>
                    </>
                  ) : undefined}
                </div>

                <div className="flex-1 basis-1/2 sm:basis-auto">
                  <Text type="secondary">Delivery date</Text>

                  <div className="text-xl">
                    {request.target_date
                      ? parseDateToMMMDD(request.target_date, true)
                      : '-'}
                  </div>
                </div>

                <div className="flex-none">
                  <Text type="secondary">Request status</Text>

                  <div>
                    <Tag color={getStatusColor(request.status)}>
                      {getRequestStatusLabel(request.status)}
                    </Tag>
                  </div>
                </div>
              </div>
            </div>

            {request.status === 'waiting_approval' ? (
              <div className="flex gap-4">
                <Button
                  className="btn-green-outline flex-auto"
                  size="large"
                  icon={<CheckOutlined className="text-base" />}
                  loading={saving}
                  onClick={() => onWrapUpdate({ approval_status: 'approved' })}
                >
                  Approve
                </Button>

                <Button
                  className="btn-red-outline flex-auto"
                  size="large"
                  icon={<CloseOutlined className="text-base" />}
                  loading={saving}
                  onClick={() => setOpenFeedbackModal(true)}
                >
                  Reject
                </Button>
              </div>
            ) : undefined}

            <div>
              <Comment
                type="requests"
                typeUuid={request.uuid}
                scrollClassName="max-h-[calc(80vh-200px)] mt-4"
                projectUuid={request.project?.uuid ?? ''}
              />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </Box>
    </div>
  );
};

export default RequestView;
