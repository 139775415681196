import { FC, useState } from 'react';
import { message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import './index.style.scss';

export interface ButtonDownloadAllFilesProps {
  resource: 'task' | 'request';
  ids: string[];
}

const ButtonDownloadAllFiles: FC<ButtonDownloadAllFilesProps> = ({
  resource,
  ids,
}) => {
  const [downloading, setDownloading] = useState(false);

  const clickHandler = async () => {
    try {
      setDownloading(true);

      const res = await apiRequests.post(`${apiRoutes.MASS_DOWNLOAD}`, {
        full_download: true,
        [`${resource}_ids`]: ids,
      });

      message.success(res?.data?.message);
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <button
      type="button"
      onClick={clickHandler}
      className="btn-download-all"
      disabled={downloading}
    >
      <DownloadOutlined />
      Download all files
    </button>
  );
};

export default ButtonDownloadAllFiles;
