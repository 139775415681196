import { FC, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Form, message, Typography } from 'antd';
import Box from 'src/components/Box';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import { UserResponse } from 'src/types';
import { FormBuilderData } from 'src/types/formBuilder';
import RequestForm from './RequestForm';
import { IoArrowBack } from 'react-icons/io5';
import FormButtons from 'src/components/FormBuilder/FormButtons';
import useRequestFormActions from './useRequestFormActions';

interface RequestUpdateProps {
  request: Record<string, any>;
  formBuilder: FormBuilderData;
  resetFormTrigger?: number;
  saveRequest: (values: any) => Promise<Record<string, any>>;
}

const RequestUpdate: FC<RequestUpdateProps> = ({
  request,
  formBuilder,
  resetFormTrigger = 0,
  saveRequest,
}) => {
  const [form] = Form.useForm();
  const user: UserResponse = useSelector(
    (globalState: any) => globalState.auth.user
  );

  const navigate = useNavigate();

  const { saving, onDiscard, onSubmit, onUpdate } = useRequestFormActions({
    form,
    formBuilder,
    ignoreUploadsOnSubmit: true,
    onSubmit: saveRequest,
    onUpdate: saveRequest,
    onDiscard: async () => {
      await apiRequests.delete(`${apiRoutes.PROJECT_REQUEST}/${request.uuid}`);
      message.success('Draft deleted!');
      navigate('/dashboard');
    },
  });

  useEffect(() => {
    if (resetFormTrigger > 0) {
      form.resetFields();
    }
  }, [form, resetFormTrigger]);

  return (
    <div>
      <div className="rounded-md border border-red-500 bg-red-50 px-4 py-2">
        <Typography.Text>
          Drafts are NOT visible to our team until you submit them. Need to
          connect with us first? Simply click your profile icon to book a call.
          Once your request is submitted, you’ll also have the option to message
          your project manager or schedule a call.
        </Typography.Text>
      </div>

      <div className="my-2 flex flex-wrap items-center justify-between overflow-hidden">
        <div className="flex-1">
          <Button
            type="link"
            size="large"
            icon={<IoArrowBack />}
            style={{ paddingLeft: 0 }}
            onClick={() => {
              navigate('/dashboard');
            }}
          >
            Go back
          </Button>
        </div>

        <div className="flex flex-wrap gap-2">
          <FormButtons
            form={form}
            saving={saving}
            onSubmit={onSubmit}
            onDiscard={
              request.requester?.uuid === user.uuid ? onDiscard : undefined
            }
          />

          <Link
            to={`/requests/${request.uuid}/duplicate`}
            className="mr-1 text-[#0044CC]"
          >
            <Button>Duplicate</Button>
          </Link>
        </div>
      </div>

      <Box>
        <RequestForm
          form={form}
          request={request}
          formBuilder={formBuilder}
          saving={saving}
          onFormUpdate={onUpdate}
          onFieldUpdate={saveRequest}
          footer={
            <FormButtons
              form={form}
              saving={saving}
              onSubmit={onSubmit}
              onDiscard={
                request.requester?.uuid === user.uuid ? onDiscard : undefined
              }
              showSavingLoading
            />
          }
          header
        />
      </Box>
    </div>
  );
};

export default RequestUpdate;
