import { FC } from 'react';
import RichTextEditor, {
  RichTextEditorProps,
} from 'src/components/RichTextEditor';
import { FormBuilderFieldTextEditor } from 'src/types/formBuilder';

interface FieldTextEditorProps {
  id?: string;
  value?: RichTextEditorProps['value'];
  data: FormBuilderFieldTextEditor;
  disabled: boolean;
  onChange?: RichTextEditorProps['onChange'];
}

const FieldTextEditor: FC<FieldTextEditorProps> = ({
  id,
  value,
  data,
  disabled,
  onChange,
}) => {
  return (
    <RichTextEditor
      id={id}
      value={value}
      className="min-h"
      readonly={disabled}
      placeholder={data.placeholder}
      onChange={onChange}
    />
  );
};

export default FieldTextEditor;
