import { UploadFileApi } from 'src/types';
import ENVIRONMENT from './environments';

export function sortTasksByStatus<T extends Object[]>(data: T) {
  return data
    .sort((a: any, b: any) => {
      if (a.task_status === 'pending') {
        return b.task_status === 'pending' ? 0 : -1;
      }

      return b.task_status === 'pending' ? 1 : 0;
    })
    .sort((a: any, b: any) => {
      if (a.task_status === 'waiting_approval') {
        return b.task_status === 'waiting_approval' ? 0 : -1;
      }

      return b.task_status === 'waiting_approval' ? 1 : 0;
    });
}

export function sortTaskByDueDate<T extends Object[]>(data: T) {
  return data.sort((a: any, b: any) => {
    if (!a.task_end_date) {
      return b.task_end_date ? 1 : 0;
    }

    if (!b.task_end_date) {
      return -1;
    }

    return (
      new Date(a.task_end_date).getTime() - new Date(b.task_end_date).getTime()
    );
  });
}

export function sortRequestByStatus<T extends Object[]>(data: T) {
  return data.sort((a: any, b: any) => {
    if (a.status === 'waiting_approval') {
      return b.status === 'waiting_approval' ? 0 : -1;
    }

    return b.status === 'waiting_approval' ? 1 : 0;
  });
}

export function mapModelToUploadFileApi(uploads: any[]) {
  return uploads.map(
    (upload): UploadFileApi => ({
      uid: upload.uuid,
      name: upload.name,
      url: `${ENVIRONMENT.REACT_APP_UPLOADS_PATH}/${upload.uuid}.${upload.extension}`,
      status: 'done',
      extension: upload.extension,
      size: Number(upload.size),
      response: {
        uuid: upload.uuid,
        url: `${ENVIRONMENT.REACT_APP_UPLOADS_PATH}/${upload.uuid}.${upload.extension}`,
        stated: true,
      },
    })
  );
}
