import { FC } from 'react';
import { Select, SelectProps } from 'antd';
import { FormBuilderFieldSelect } from 'src/types/formBuilder';

interface FieldSelectProps {
  id?: string;
  value?: SelectProps['value'];
  data: FormBuilderFieldSelect;
  disabled: boolean;
  onChange?: SelectProps['onChange'];
}

const FieldSelect: FC<FieldSelectProps> = ({
  id,
  value,
  data,
  disabled,
  onChange,
}) => {
  return (
    <Select
      id={id}
      value={value}
      className={disabled ? 'read-only' : undefined}
      placeholder={data.placeholder}
      mode={data.mode === 'multiple' ? 'multiple' : undefined}
      options={data.options}
      onChange={onChange}
      allowClear
    />
  );
};

export default FieldSelect;
