import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'src/store';
import { Button } from 'antd';

const Home = () => {
  const authToken = useSelector(
    (globalState: RootState) => globalState.auth.token
  );

  return (
    <>
      <div
        className="px-6 pt-14 lg:px-8"
        style={{
          background:
            'linear-gradient(25deg, rgba(201,222,255,1) 17%, rgba(250,252,255,1) 60%, rgba(201,222,255,1) 83%)',
        }}
      >
        <div className="mx-auto max-w-4xl py-6 sm:py-8">
          <div className="text-center">
            <h1
              className="mb-4 text-4xl font-extrabold text-primary"
              style={{
                textShadow: '0px 4px 4px rgba(0, 68, 204, 0.25)',
              }}
            >
              Everything You Need In One Place
            </h1>

            <p className="mb-4 text-primary">
              Lionshare is the all-in-one solution that effortlessly connects
              you with our team on any device. With Lionshare, you can enjoy a
              streamlined request system, efficient project management,
              real-time notifications, access to resources, and much more.
            </p>

            <Link
              to={authToken ? '/dashboard' : '/login'}
              className="font-medium"
            >
              <Button type="primary" size="large" className="mb-2">
                {authToken ? 'Go to Dashboard' : 'Lets Get Connected'}
              </Button>
            </Link>

            <img src="/assets/dash-ls.png" alt="dashboard" className="w-full" />
          </div>
        </div>
      </div>

      <div id="features" className="bg-white p-8">
        <div className="mx-auto max-w-4xl">
          <span className="rounded-full bg-primary px-4 py-1 text-sm leading-none text-white shadow-sm">
            FEATURES
          </span>

          <div className="flex flex-col-reverse items-center pt-4 text-center sm:text-left md:flex-row">
            <img
              src="/assets/easy-navigation.png"
              alt="responsive interface"
              className="w-full max-w-[500px]"
            />

            <div className="space-y-3 pb-8 text-primary md:px-10 md:pb-0">
              <h3 className="text-2xl font-extrabold sm:text-3xl">
                Easy navigation
              </h3>

              <p>Explore our new menu, now simpler and more intuitive.</p>

              <Button type="primary">
                <Link
                  to={authToken ? '/dashboard' : '/login'}
                  className="font-medium"
                >
                  ACCESS NOW
                </Link>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#f2f5fc] p-8">
        <div className="mx-auto max-w-4xl">
          <div className="flex flex-col items-center text-center sm:text-left md:flex-row">
            <div className="space-y-3 pb-8 text-primary md:px-10 md:pb-0">
              <h3 className="text-2xl font-extrabold sm:text-3xl">
                Everything in one place
              </h3>

              <p>Easily follow and manage your tasks.</p>

              <Button type="primary">
                <Link
                  to={authToken ? '/dashboard' : '/login'}
                  className="font-medium"
                >
                  ACCESS NOW
                </Link>
              </Button>
            </div>

            <img
              src="/assets/everything-one-place.png"
              alt="responsive interface"
              className="w-full max-w-[500px]"
            />
          </div>
        </div>
      </div>

      <div className="bg-white p-8">
        <div className="mx-auto max-w-4xl">
          <div className="flex flex-col-reverse items-center text-center sm:text-left md:flex-row">
            <img
              src="/assets/make-request.png"
              alt="responsive interface"
              className="w-full max-w-[500px]"
            />

            <div className="space-y-3 pb-8 text-primary md:px-10 md:pb-0">
              <h3 className="text-2xl font-extrabold sm:text-3xl">
                Make requests in seconds
              </h3>

              <p>The new task creation screen is easier and simpler to use.</p>

              <Button type="primary">
                <Link
                  to={authToken ? '/dashboard' : '/login'}
                  className="font-medium"
                >
                  ACCESS NOW
                </Link>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#f2f5fc] p-8">
        <div className="mx-auto max-w-4xl">
          <div className="flex flex-col items-center text-center sm:text-left md:flex-row">
            <div className="space-y-3 pb-8 text-primary md:px-10 md:pb-0">
              <h3 className="text-2xl font-extrabold sm:text-3xl">
                Mobile friendly
              </h3>

              <p>
                Optimized design for effortless mobile browsing and user
                experience.
              </p>

              <Button type="primary">
                <Link
                  to={authToken ? '/dashboard' : '/login'}
                  className="font-medium"
                >
                  ACCESS NOW
                </Link>
              </Button>
            </div>

            <img
              src="/assets/mobile-friendly.png"
              alt="responsive interface"
              className="w-full max-w-[500px]"
            />
          </div>
        </div>
      </div>

      <div className="bg-primary px-6 py-6 lg:px-8">
        <div className="mx-auto flex max-w-4xl flex-col items-center justify-center gap-8 sm:flex-row">
          <h3 className="text-xl font-medium text-white sm:text-2xl">
            Let's Get Connected
          </h3>

          <img
            src="/assets/Arrow.png"
            alt="arrow"
            className="hidden max-w-[150px] sm:block"
          />

          <Link
            to={authToken ? '/dashboard' : '/login'}
            className="rounded-full bg-white px-6 py-2 text-lg font-medium text-primary shadow-sm hover:bg-slate-100"
          >
            Access Now
          </Link>
        </div>
      </div>

      <div className="bg-white py-1 text-center">
        <span className="text-sm">Copyright © {new Date().getFullYear()} </span>
      </div>
    </>
  );
};

export default Home;
