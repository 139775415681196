import { FC, FunctionComponent, ReactNode, useMemo } from 'react';
import { Checkbox, Form, FormInstance } from 'antd';
import { Rule } from 'antd/es/form';
import {
  FormBuilderData,
  FormBuilderFormData,
  FormBuilderVisibleCondition,
} from 'src/types/formBuilder';
import FieldHeader from './fields/FieldHeader';
import FieldTextArea from './fields/FieldTextArea';
import FieldInput from './fields/FieldInput';
import FieldCheckbox from './fields/FieldCheckbox';
import FieldDate from './fields/FieldDate';
import FieldSelect from './fields/FieldSelect';
import FieldTextEditor from './fields/FieldTextEditor';
import FieldUpload from './fields/FieldUpload';
import FieldRadio from './fields/FieldRadio';
import FormItem from './FormItem';

export function isFieldVisible(
  visible: FormBuilderVisibleCondition,
  fieldValue: any
) {
  if (visible.values === undefined) {
    return Array.isArray(fieldValue) ? fieldValue.length > 0 : !!fieldValue;
  }

  if (Array.isArray(fieldValue)) {
    if (Array.isArray(visible.values)) {
      if (visible.operator === 'and') {
        return (
          visible.values.filter((x) => !fieldValue.includes(x)).length === 0
        );
      }

      return visible.values.find((x) => fieldValue.includes(x)) !== undefined;
    }

    return visible.values === null
      ? fieldValue.length === 0
      : fieldValue.includes(visible.values);
  }

  if (Array.isArray(visible.values)) {
    return visible.values.includes(fieldValue);
  }

  return visible.values === null
    ? fieldValue === undefined || fieldValue === null
    : fieldValue === visible.values;
}

const inputComponents: Record<
  FormBuilderFormData['type'],
  FunctionComponent<{ data: any; rules?: Rule[]; disabled: boolean }>
> = {
  date: FieldDate,
  checkbox: FieldCheckbox,
  radio: FieldRadio,
  select: FieldSelect,
  input: FieldInput,
  textArea: FieldTextArea,
  textEditor: FieldTextEditor,
  upload: FieldUpload,
  header: FieldHeader,
  formRef: FieldInput,
};

interface FormBuilderAnswersVisibleControlProps {
  form: FormInstance;
  visible: FormBuilderVisibleCondition;
  children: ReactNode;
}

const FormBuilderAnswersVisibleControl: FC<
  FormBuilderAnswersVisibleControlProps
> = ({ form, visible, children }) => {
  const value = Form.useWatch(visible.field, form);

  const isVisible = useMemo(
    () => isFieldVisible(visible, value),
    [visible, value]
  );

  return isVisible ? <>{children}</> : null;
};

interface FormBuilderAnswersInputsProps {
  data: FormBuilderFormData[];
  form: FormInstance;
  listData?: FormBuilderData[];
  disabled: boolean;
  isFormRef?: boolean;
}

const FormBuilderAnswersInputs: FC<FormBuilderAnswersInputsProps> = ({
  data,
  form,
  listData,
  disabled,
  isFormRef = false,
}) => {
  return (
    <>
      {data.map((inputData) => {
        if (inputData.type === 'formRef') {
          const refForm = listData?.find(
            (item) => item.id === inputData.formId
          );

          if (!refForm?.form) {
            return undefined;
          }

          return inputData.visible ? (
            <FormBuilderAnswersVisibleControl
              key={inputData.formId}
              form={form}
              visible={inputData.visible}
            >
              <FormBuilderAnswersInputs
                key={inputData.formId}
                data={refForm.form}
                form={form}
                listData={listData}
                disabled={disabled}
                isFormRef
              />
            </FormBuilderAnswersVisibleControl>
          ) : (
            <FormBuilderAnswersInputs
              key={inputData.formId}
              data={refForm.form}
              form={form}
              listData={listData}
              disabled={disabled}
              isFormRef
            />
          );
        }

        const Comp = inputComponents[inputData.type];

        if ((inputData.hideInsideFormRef !== true || !isFormRef) && Comp) {
          return inputData.visible ? (
            <FormBuilderAnswersVisibleControl
              key={inputData.name}
              form={form}
              visible={inputData.visible}
            >
              <FormItem data={inputData}>
                <Comp data={inputData} disabled={disabled} />
              </FormItem>
            </FormBuilderAnswersVisibleControl>
          ) : (
            <FormItem key={inputData.name} data={inputData}>
              <Comp data={inputData} disabled={disabled} />
            </FormItem>
          );
        }

        return undefined;
      })}
    </>
  );
};

interface FormBuilderAnswersProps {
  data: FormBuilderFormData[];
  form: FormInstance;
  disabled?: boolean;
  listData?: FormBuilderData[];
}

const FormBuilderAnswers: FC<FormBuilderAnswersProps> = ({
  data,
  form,
  listData,
  disabled = false,
}) => {
  return (
    <div className="overflow-hidden">
      <Form.Item name="submitting" valuePropName="checked" hidden>
        <Checkbox />
      </Form.Item>

      <Form.Item name="alert_submitting" valuePropName="checked" hidden>
        <Checkbox />
      </Form.Item>

      <FormBuilderAnswersInputs
        data={data}
        listData={listData}
        form={form}
        disabled={disabled}
      />
    </div>
  );
};

export default FormBuilderAnswers;
