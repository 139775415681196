import { FC } from 'react';
import { Tooltip } from 'antd';
import './index.style.scss';

interface ProgressBarDoubleProps {
  mainPercent: number;
  secondaryPercent: number;
  mainTooltip?: string;
  secondaryTooltip?: string;
  backgroundTooltip?: string;
}

const ProgressBarDouble: FC<ProgressBarDoubleProps> = ({
  mainPercent,
  secondaryPercent,
  mainTooltip,
  secondaryTooltip,
  backgroundTooltip,
}) => {
  const biggerPercent =
    mainPercent > secondaryPercent ? mainPercent : secondaryPercent;

  let backgroundPercent = 100 - biggerPercent;

  backgroundPercent = backgroundPercent < 0 ? 0 : backgroundPercent;

  backgroundPercent = backgroundPercent > 100 ? 100 : backgroundPercent;

  return (
    <div
      className={`progressbar-double${
        mainPercent > 100 ? ' progressbar-double-danger' : ''
      }`}
    >
      <Tooltip title={backgroundTooltip} placement="topLeft">
        <div
          className="progressbar-double__background-bar"
          style={{
            width: `calc(${backgroundPercent}% + 3px)`,
            left:
              biggerPercent < 3 ? undefined : `calc(${biggerPercent}% - 3px)`,
          }}
        />
      </Tooltip>

      <Tooltip title={secondaryTooltip}>
        <div
          className={`progressbar-double__secondary-bar${
            secondaryPercent > 100
              ? ' progressbar-double__secondary-bar-danger'
              : ''
          }`}
          style={{
            width:
              mainPercent >= secondaryPercent
                ? `${secondaryPercent}%`
                : `calc(${secondaryPercent - mainPercent}% + 3px)`,
            left:
              mainPercent >= secondaryPercent || mainPercent < 3
                ? undefined
                : `calc(${mainPercent}% - 3px)`,
          }}
        />
      </Tooltip>

      <Tooltip title={mainTooltip}>
        <div
          className={`progressbar-double__main-bar${
            mainPercent > 100 ? ' progressbar-double__main-bar-danger' : ''
          }`}
          style={{ width: `${mainPercent}%` }}
        />
      </Tooltip>
    </div>
  );
};

export default ProgressBarDouble;
