import { FC, useState } from 'react';
import { Button, FormInstance, Modal } from 'antd';
import { PiWarningCircleLight } from 'react-icons/pi';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import { formScrollToFieldOnError } from 'src/utils/form';

export const getFormData = async (form: FormInstance, noAlert = false) => {
  try {
    const values = await form.validateFields();

    delete values.submitting;

    delete values.alert_submitting;

    Object.keys(values).forEach((key) => {
      if (key.endsWith('_force_render')) {
        delete values[key];
      }
    });

    return values;
  } catch (error) {
    if (!noAlert) {
      formScrollToFieldOnError(form, error);
      asyncErrorHandler(error);
    }

    throw error;
  }
};

interface FormSubmitButtonProps {
  form: FormInstance;
  saving?: boolean;
  onSubmit: (values: any) => void;
}

const FormSubmitButton: FC<FormSubmitButtonProps> = ({
  form,
  saving,
  onSubmit,
}) => {
  const [openAlert, setOpenAlert] = useState(false);

  return (
    <>
      <Modal
        title={
          <div className="flex items-center gap-2">
            <PiWarningCircleLight className="text-2xl text-yellow-500" />
            Almost there
          </div>
        }
        open={openAlert}
        onOk={() => {
          setOpenAlert(false);
          getFormData(form)
            .then((values) => onSubmit(values))
            .catch(() => {
              //
            });
        }}
        onCancel={() => setOpenAlert(false)}
        okText="Submit anyway"
        cancelText="Update request"
        closable={false}
        maskClosable={false}
      >
        <p className="mb-4">
          Some details are missing. Would you like to update or submit anyway?
          If you submit anyway, we’ll follow up in chat to help complete any
          missing details and get started.
        </p>
      </Modal>

      <Button
        type="primary"
        disabled={saving}
        onClick={async () => {
          form.setFieldValue('submitting', true);

          try {
            await getFormData(form);

            form.setFieldValue('submitting', false);
            form.setFieldValue('alert_submitting', true);

            const values = await getFormData(form, true).catch((error) => {
              form.setFieldValue('alert_submitting', false);
              setOpenAlert(true);
              getFormData(form);
              throw error;
            });

            form.setFieldValue('alert_submitting', false);

            onSubmit(values);
          } catch (_) {
            //
          } finally {
            form.setFieldValue('submitting', false);
          }
        }}
      >
        Submit project brief
      </Button>
    </>
  );
};

export default FormSubmitButton;
