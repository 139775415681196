import { FC, useEffect } from 'react';
import dayjs from 'dayjs';
import { MdOutlineEmail } from 'react-icons/md';
import { Badge, Empty, List, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import RichTextEditor from 'src/components/RichTextEditor';
import UserAvatar from 'src/components/UserAvatar';
import Box from 'src/components/Box';
import { RootState } from 'src/store';
import {
  getInbox,
  updateRelatedUserMention,
  decreaseInboxUnreadCount,
} from 'src/store/inbox/reducer';
import { InboxResponse, UserResponse } from 'src/types';
import { mentionCommentParser } from 'src/utils/stringHelpers';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';

const Inbox: FC = () => {
  const user: UserResponse = useSelector(
    (globalState: any) => globalState.auth.user
  );
  const inbox = useSelector((globalState: RootState) => globalState.inbox);
  const { loading, data, count } = inbox;
  const dispatch = useDispatch();

  const findRelatedUser = (item: InboxResponse) => {
    return item.related_users.find((x) => x.uuid === user.uuid);
  };

  const markAsRead = (item: InboxResponse) => {
    if (findRelatedUser(item)?.read_at) {
      return;
    }

    apiRequests
      .post(`${apiRoutes.MENTIONS}/${item.uuid}/read`)
      .then(() => dispatch(getInbox()))
      .catch((error) => asyncErrorHandler(error));

    dispatch(
      updateRelatedUserMention({
        user,
        uuid: item.uuid,
        newValues: { read_at: dayjs().format() },
      })
    );

    dispatch(decreaseInboxUnreadCount(1));
  };

  useEffect(() => {
    dispatch(getInbox());
  }, [dispatch]);

  return (
    <>
      <div className="mb-2 px-1 font-bold text-blue-600">
        <MdOutlineEmail className="mr-2 inline-block text-2xl" />

        <span className="mr-2 align-middle">My inbox</span>

        <Badge count={count ?? 0} />
      </div>

      <Box className="p-2" noPadding>
        {loading && !data ? (
          <Skeleton className="mt-4" />
        ) : (
          <List
            dataSource={data?.data}
            locale={{
              emptyText: (
                <Empty
                  description={
                    <span className="text-gray-400">
                      Any requests that you are tagged in will show in here.
                    </span>
                  }
                />
              ),
            }}
            renderItem={(item) => (
              <List.Item
                key={item.uuid}
                className="rounded-md !px-2 hover:bg-[#0000000f]"
              >
                <Link
                  to={`${
                    item.location?.type === 'request'
                      ? `/requests/${item.location?.uuid}?`
                      : `?task_id=${item.location?.uuid}&`
                  }target_comment_id=${item.uuid}`}
                  className="relative flex w-full gap-4 px-3 !text-inherit"
                  onClick={() => markAsRead(item)}
                >
                  {!findRelatedUser(item)?.read_at ? (
                    <div className="absolute left-0 top-3 z-10 h-2 w-2 rounded-full bg-blue-700" />
                  ) : undefined}

                  <div>
                    <UserAvatar user={item.creator} />
                  </div>

                  <div className="flex flex-col">
                    <span
                      className={
                        !findRelatedUser(item)?.read_at
                          ? 'font-bold'
                          : undefined
                      }
                    >
                      {item.creator?.name}
                    </span>

                    <RichTextEditor
                      className={`comment-styles resume resume-inbox ${
                        item.deleted_at ? 'italic' : ''
                      }`}
                      defaultValue={mentionCommentParser(item.text)}
                      readonly
                      noContainer
                    />
                  </div>
                </Link>
              </List.Item>
            )}
          />
        )}
      </Box>
    </>
  );
};

export default Inbox;
