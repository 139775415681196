import { Tag, Tooltip, Typography } from 'antd';
import { FaRegCopy } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  convertMinToHrsMin,
  getRequestStatusLabel,
  getStatusColor,
  parseDateToMMMDD,
} from 'src/utils/stringHelpers';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

const { Text } = Typography;

export interface RequestItemProps {
  request: Record<string, any>;
}

const RequestItem = ({ request }: RequestItemProps) => {
  const isCompleted = request.status === 'completed';

  const { lg } = useBreakpoint();

  const clientName = `by ${request.requester?.name ?? 'No user'}`;

  return (
    <Link
      to={`/requests/${request.uuid}`}
      className="box-content flex flex-wrap items-center gap-x-2 rounded-md bg-white p-3 hover:bg-[#0000000f] lg:flex-nowrap"
      preventScrollReset
    >
      <div className="w-full overflow-hidden leading-none lg:w-auto lg:flex-auto">
        <div className="text-xs text-gray-400">{request.title}</div>

        <Text className="font-bold" ellipsis>
          {lg ? (
            request.subject
          ) : (
            <Tooltip title={request.subject}>{request.subject}</Tooltip>
          )}
        </Text>
      </div>

      <div className="flex w-full flex-none flex-wrap items-center gap-x-2 sm:flex-nowrap lg:w-auto">
        <div className="w-full flex-none overflow-hidden leading-none sm:w-auto sm:flex-auto lg:w-[200px] lg:flex-none">
          <Text className="!text-xs !text-gray-400" ellipsis>
            {clientName}
          </Text>

          <div className="text-sm">
            {isCompleted
              ? request.completed_at
                ? `Completed: ${parseDateToMMMDD(request.completed_at)}`
                : 'No completed date'
              : request.target_date
              ? `Delivery: ${parseDateToMMMDD(request.target_date, true)}`
              : 'No due date'}
          </div>
        </div>

        <div className="w-[160px] flex-auto sm:flex-none">
          <div className="text-xs text-gray-400">
            {isCompleted ? 'Spent' : 'Estimated'}
          </div>

          <div className="text-sm">
            {isCompleted
              ? `${convertMinToHrsMin(request.time_spent ?? 0)} hours`
              : request.estimated_min || request.estimated_max
              ? `${convertMinToHrsMin(
                  request.estimated_min ?? 0
                )} - ${convertMinToHrsMin(request.estimated_max ?? 0)} hours`
              : 'No estimated time'}
          </div>
        </div>

        <div className="flex w-[130px] flex-none items-center justify-end gap-2">
          <Tag color={getStatusColor(request.status)} style={{ margin: 0 }}>
            {getRequestStatusLabel(request.status)}
          </Tag>

          <Link
            to={`/requests/${request.uuid}/duplicate`}
            className="inline-block text-sm text-[#0044CC]"
          >
            <FaRegCopy />
          </Link>
        </div>
      </div>
    </Link>
  );
};

export default RequestItem;
