import { FC, useMemo } from 'react';
import { Badge, Menu, MenuProps } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BsClipboardData } from 'react-icons/bs';
import { FiFileText, FiUsers } from 'react-icons/fi';
import { GrDocumentTime } from 'react-icons/gr';
import { LuUsers } from 'react-icons/lu';
import {
  MdOutlineAddTask,
  MdOutlineDashboard,
  MdOutlineEmail,
  MdOutlineFolderCopy,
} from 'react-icons/md';
import { PiWarningOctagon } from 'react-icons/pi';
import { TbFileInfo, TbUserPlus } from 'react-icons/tb';
import Box from 'src/components/Box';
import { UserResponse } from 'src/types';
import { RootState } from 'src/store';
import { useAppTour } from 'src/context/appTourContext';

type MenuItem = Required<MenuProps>['items'][number];

const linkClassName = 'flex items-center gap-2 font-semibold';
const iconClassName = 'text-2xl';

export const showUsageCard = (user: UserResponse) => {
  return user.contact.customer?.uuid === 'd8fed636-78b9-4ea9-bc13-b399521f1b2a';
};

interface MenuLeftProps {
  onInvite: () => void;
  hidePendingItem?: boolean;
}

const MenuLeft: FC<MenuLeftProps> = ({ hidePendingItem, onInvite }) => {
  const user: UserResponse = useSelector(
    (globalState: any) => globalState.auth.user
  );
  const inboxUnread = useSelector(
    (globalState: RootState) => globalState.inbox.count
  );

  const customer = user.contact?.customer;

  const { data: project } = useSelector(
    (globalState: RootState) => globalState.projectRetainer
  );

  const { setStepTarget } = useAppTour();

  const { pathname } = useLocation();

  const items: (MenuItem | Record<string, any>)[] = [
    {
      key: 'dashboard',
      href: '/dashboard',
      label: (
        <Link to="/dashboard" className={linkClassName}>
          <MdOutlineDashboard className={iconClassName} />
          Dashboard
        </Link>
      ),
    },
    ...(hidePendingItem
      ? []
      : [
          {
            key: 'pending',
            href: '/pending',
            className: 'menu-item-bg-danger',
            label: (
              <Link to="/pending" className={linkClassName}>
                <PiWarningOctagon className={iconClassName} strokeWidth="2" />
                Pending
              </Link>
            ),
          },
        ]),
    {
      key: 'inbox',
      href: '/inbox',
      label: (
        <Link to="/inbox" className={linkClassName}>
          <MdOutlineEmail className={iconClassName} />
          <span className="flex-auto">My inbox</span>
          <Badge count={inboxUnread ?? 0} />
        </Link>
      ),
    },
    {
      key: 'create-request',
      href: '/requests/create',
      label: (
        <Link
          to={'/requests/create'}
          className={linkClassName}
          preventScrollReset={true}
        >
          <MdOutlineAddTask className={iconClassName} />
          Create new request
        </Link>
      ),
    },
    ...(project && showUsageCard(user)
      ? [
          {
            key: 'usage',
            href: '/usage',
            label: (
              <Link to="/usage" className={linkClassName}>
                <GrDocumentTime className={iconClassName} />
                Usage
              </Link>
            ),
          },
        ]
      : []),
    {
      key: 'contacts',
      href: '/contacts',
      label: (
        <Link to="/contacts" className={linkClassName}>
          <FiUsers className={iconClassName} />
          Team members
        </Link>
      ),
    },
    {
      key: 'invite',
      onClick: () => onInvite(),
      label: (
        <span className={linkClassName} ref={(node) => setStepTarget(2, node)}>
          <TbUserPlus className={iconClassName} />
          Invite a team member
        </span>
      ),
    },
    ...(customer?.dashboard_link_1 || customer?.dashboard_link_2
      ? [
          {
            key: 'reports',
            href: '/report',
            label: (
              <Link to="/report" className={linkClassName}>
                <BsClipboardData className={iconClassName} />
                Reports
              </Link>
            ),
          },
        ]
      : []),
    {
      key: 'client-resources',
      href: '/client-resources',
      label: (
        <Link
          to="/client-resources"
          className={linkClassName}
          ref={(node) => setStepTarget(3, node)}
        >
          <TbFileInfo className={iconClassName} />
          Resources
        </Link>
      ),
    },
    {
      key: 'assets',
      href: '/project-assets',
      label: (
        <Link to="/project-assets" className={linkClassName}>
          <MdOutlineFolderCopy className={iconClassName} />
          My assets
        </Link>
      ),
    },
    {
      key: 'account',
      href: '/company/profile',
      label: (
        <Link to="/company/profile" className={linkClassName}>
          <FiFileText className={iconClassName} />
          My account
        </Link>
      ),
    },
  ];

  const selectedItem = useMemo(
    () => items.find((item: any) => item.href === pathname),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  return (
    <Box className="rounded-t-none" noPadding>
      <div className="break-words px-7 py-3">
        <div className="font-bold">{user.contact?.customer?.business_name}</div>

        {project ? (
          <div className="text-xs">
            {user.contact?.customer?.subscription?.name} -{' '}
            <LuUsers className="inline align-text-bottom text-base" />{' '}
            {user.contact?.customer?.contacts_count} members
          </div>
        ) : undefined}
      </div>

      <Menu
        mode="inline"
        items={items as MenuItem[]}
        selectedKeys={selectedItem ? [selectedItem.key] : []}
        style={{ background: 'none', borderInlineEnd: 'none' }}
      />
    </Box>
  );
};

export default MenuLeft;
