import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, message, Result } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import Box from 'src/components/Box';
import { FormBuilderData } from 'src/types/formBuilder';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import listFormsData from './listForms.json';
import RequestForm from './RequestForm';
import { IoArrowBack } from 'react-icons/io5';
import useRequestFormActions from './useRequestFormActions';
import FormButtons from '../../components/FormBuilder/FormButtons';

const listForms = listFormsData as FormBuilderData[];

const RequestCreatePage = () => {
  const [form] = Form.useForm();

  const params = useParams();
  const navigate = useNavigate();

  const formBuilder = useMemo(() => {
    return cloneDeep(
      listForms.find((item) => item.id === parseInt(params.id ?? '0'))
    );
  }, [params.id]);

  const saveRequest = async (values: any) => {
    const response = await apiRequests.post(apiRoutes.PROJECT_REQUEST, values);

    if (response.data.data.status === 'draft') {
      message.success('Draft created');
    } else {
      message.success('Request submitted');
    }

    navigate(`/requests/${response.data.data.uuid}`);

    return response.data.data;
  };

  const { saving, onDraft, onSubmit } = useRequestFormActions({
    form,
    formBuilder,
    onDraft: saveRequest,
    onSubmit: saveRequest,
  });

  return (
    <div>
      <div className="mb-2 flex flex-wrap items-center justify-between overflow-hidden">
        <Button
          type="link"
          size="large"
          icon={<IoArrowBack />}
          style={{ paddingLeft: 0 }}
          onClick={() => {
            navigate('/requests/create');
          }}
        >
          Go back
        </Button>

        <FormButtons
          form={form}
          saving={saving}
          onDraft={onDraft}
          onSubmit={onSubmit}
        />
      </div>

      <Box>
        {formBuilder ? (
          <RequestForm
            form={form}
            formBuilder={formBuilder}
            saving={saving}
            footer={
              <FormButtons
                form={form}
                saving={saving}
                onDraft={onDraft}
                onSubmit={onSubmit}
                showSavingLoading
              />
            }
            confirmBeforeRedirect
            header
          />
        ) : (
          <Result status="404">Not found</Result>
        )}
      </Box>
    </div>
  );
};

export default RequestCreatePage;
