import Card from 'src/components/Card';
import Box from 'src/components/Box';
import { FormBuilderData } from 'src/types/formBuilder';
import listFormsData from './listForms.json';
import { Typography } from 'antd';

const listForms = listFormsData as FormBuilderData[];

const RequestListCreatePage = () => {
  return (
    <Box>
      <h1 className="mb-2 text-xl text-blue-700 lg:text-2xl">
        Let's bring your ideas to life!
      </h1>

      <Typography.Text type="secondary">
        Save time with our easy multiple-choice request forms for our most
        popular services. Don’t see what you need? Choose ‘Other’ and share your
        vision with us.
      </Typography.Text>

      <div className="grid grid-flow-row gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {listForms.map(
          (request) =>
            !request.disabled && (
              <Card
                key={request.name}
                name={request.name}
                link={`/requests/create/${request.id}`}
                imageUrl={request.imageUrl}
                tags={request.tags}
                buttonName="Request"
                ribbon={
                  request.timeEstimated && `Starting ${request.timeEstimated}`
                }
              >
                {request.description}
              </Card>
            )
        )}
      </div>
    </Box>
  );
};

export default RequestListCreatePage;
