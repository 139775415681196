import { FC } from 'react';
import { Checkbox, Empty, Skeleton, Spin } from 'antd';
import { TbSubtask } from 'react-icons/tb';
import { RxQuestionMarkCircled } from 'react-icons/rx';
import { useAppTour } from 'src/context/appTourContext';
import RequestItem from '../RequestItem';

interface TaskProps {
  className?: string;
  data: any[] | undefined;
  loading?: boolean;
  showTourButton?: boolean;
  showCompletedValue?: boolean;
  onShowAll?: (showAll: boolean) => void;
}

const RequestCard: FC<TaskProps> = ({
  className = '',
  data,
  loading = false,
  showTourButton = false,
  showCompletedValue,
  onShowAll,
}) => {
  const { setOpenTour } = useAppTour();

  return (
    <div className={`flex w-full flex-col gap-2 ${className}`}>
      <div className="flex items-center gap-2 text-blue-600">
        <div className="flex flex-auto items-center gap-2">
          <TbSubtask className="text-2xl" />

          <span className="pr-2 font-bold">Requests</span>
        </div>

        {showTourButton || onShowAll ? (
          <div className="flex items-center gap-2">
            {loading && data && <Spin size="small" />}

            {onShowAll && (
              <Checkbox
                checked={showCompletedValue}
                onChange={(event) => onShowAll(event.target.checked)}
              >
                <span className="text-blue-600">Show all</span>
              </Checkbox>
            )}
            {showTourButton && (
              <button
                type="button"
                onClick={() => {
                  setOpenTour(true, 6);
                }}
              >
                <RxQuestionMarkCircled className="text-2xl" />
              </button>
            )}
          </div>
        ) : undefined}
      </div>

      <div>
        {loading && !data ? (
          <Skeleton className="py-4" />
        ) : (
          <div className="space-y-3">
            {data?.map((request) => {
              return <RequestItem key={request.uuid} request={request} />;
            })}

            {!data?.length ? (
              <Empty
                description={
                  <span className="text-gray-400">
                    You have no open requests. Click the green ‘Create new
                    request’ button to start one.
                  </span>
                }
              />
            ) : undefined}
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestCard;
