import { CSSProperties, FC, ReactNode } from 'react';

type BoxSizeTypes = 'fixed' | 'relative';

type BoxKeySizes = 'small' | 'default' | 'large';

type BoxSizes = {
  [key in BoxKeySizes]: {
    [key in BoxSizeTypes]: string;
  };
};

const boxSizes: BoxSizes = {
  small: {
    fixed: 'max-h-[260px] sm:h-[260px]',
    relative: 'max-h-[260px]',
  },
  default: {
    fixed: 'max-h-[320px] sm:h-[320px]',
    relative: 'max-h-[320px]',
  },
  large: {
    fixed: 'max-h-[400px] sm:h-[400px]',
    relative: 'max-h-[400px]',
  },
};

const paddingSizes = {
  small: 'sm:px-4 py-1 px-2',
  default: 'sm:px-6 sm:py-4 px-3 py-2',
};

interface BoxProps {
  className?: string;
  children?: ReactNode;
  noPadding?: boolean;
  size?: BoxKeySizes;
  sizeType?: BoxSizeTypes;
  padding?: keyof typeof paddingSizes;
  style?: CSSProperties;
}

const Box: FC<BoxProps> = ({
  className: classNameWrap,
  children,
  style,
  noPadding,
  size,
  sizeType = 'fixed',
  padding = 'default',
}) => {
  let className = 'w-full rounded-md bg-white';

  if (size) {
    className += ` overflow-auto${
      sizeType === 'fixed' ? ` sm:max-h-none` : ''
    } ${boxSizes[size][sizeType]}`;
  }

  if (!noPadding) className += ` ${paddingSizes[padding]}`;

  if (classNameWrap) className += ` ${classNameWrap}`;

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

export default Box;
