import { FC } from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { FormBuilderFieldTextArea } from 'src/types/formBuilder';

const { TextArea } = Input;

interface FieldTextAreaProps {
  id?: string;
  value?: TextAreaProps['value'];
  data: FormBuilderFieldTextArea;
  disabled: boolean;
  onChange?: TextAreaProps['onChange'];
}

const FieldTextArea: FC<FieldTextAreaProps> = ({
  id,
  value,
  data,
  disabled,
  onChange,
}) => {
  return (
    <TextArea
      id={id}
      value={value}
      style={{ minHeight: 150 }}
      placeholder={data.placeholder}
      readOnly={disabled}
      onChange={onChange}
    />
  );
};

export default FieldTextArea;
