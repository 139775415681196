import { FormInstance } from 'antd';

export const formScrollToFieldOnError = (
  form: FormInstance,
  error: unknown
) => {
  if (
    error &&
    typeof error === 'object' &&
    'errorFields' in error &&
    Array.isArray(error.errorFields)
  ) {
    form.scrollToField(error.errorFields[0].name, {
      block: 'center',
    });
  }
};
