import auth from './auth/reducer';
import tasks from './tasks/reducer';
import dashboardRequests from './dashboardRequests/reducer';
import pendingForms from './pendingForms/reducer';
import projectRetainer from './projectRetainer/reducer';
import dashboardProjects from './dashboardProjects/reducer';
import inbox from './inbox/reducer';
import activities from './activities/reducer';
import projectAdvertising from './projectAdvertising/reducer';
import requests from './requests/reducer';
import contacts from './contacts/reducer';
import pendingInvites from './pendingInvites/reducer';

const combinedReducer = {
  auth,
  tasks,
  projectRetainer,
  inbox,
  dashboardProjects,
  dashboardRequests,
  activities,
  projectAdvertising,
  pendingForms,
  requests,
  contacts,
  pendingInvites,
};

export default combinedReducer;
