import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBarDouble from 'src/components/ProgressBarDouble';
import { RootState } from 'src/store';
import { convertMinToHrsMin } from 'src/utils/stringHelpers';
import { Typography } from 'antd';
import useWebSocket from 'src/utils/useWebSocket';
import { getProjectRetainer } from 'src/store/projectRetainer/reducer';

interface ProjectTimeReportCardProps {
  hideSeeDetail?: boolean;
}

const ProjectTimeReportCard: FC<ProjectTimeReportCardProps> = ({
  hideSeeDetail,
}) => {
  const report = useSelector(
    (globalState: RootState) => globalState.projectRetainer.data?.time_report
  );

  const dispatch = useDispatch();

  const availableString = report?.available
    ? `${report.available < 0 ? '-' : ''}${convertMinToHrsMin(
        report.available >= 0 ? report.available : report.available * -1
      )}`
    : '00:00';

  const spentPercent = report ? (report.spent * 100) / report.budget : 0;

  const estimatedPercent = report
    ? (report.estimated * 100) / report.budget
    : 0;

  useWebSocket({
    channelName: 'model.changes',
    listen: {
      event: '.app.models.projects.request',
      callback: async () => {
        dispatch(getProjectRetainer());
      },
    },
  });

  return (
    <div className="w-full text-sm text-blue-700">
      <div className="flex justify-between">
        <span className="font-bold">Hours balance</span>

        {!hideSeeDetail && (
          <Link to="/usage" className="text-xs hover:text-blue-500 xl:hidden">
            View details
          </Link>
        )}
      </div>

      <div className="flex justify-between gap-2">
        <span>Available</span>

        <span>
          <Typography.Text
            type={report?.available < 0 ? 'danger' : undefined}
            className={report?.available < 0 ? '' : '!text-blue-700'}
          >
            {availableString}
          </Typography.Text>
          / {convertMinToHrsMin(report?.budget ?? 0)}
        </span>
      </div>

      <ProgressBarDouble
        mainPercent={spentPercent}
        secondaryPercent={estimatedPercent}
        mainTooltip={`Spent: ${convertMinToHrsMin(report?.spent ?? 0)}`}
        secondaryTooltip={`Estimated: ${convertMinToHrsMin(
          report?.estimated_original ?? 0
        )}`}
        backgroundTooltip={`Available: ${availableString}`}
      />

      {!hideSeeDetail && (
        <Link
          to="/usage"
          className="mb-2 hidden text-xs hover:text-blue-500 xl:block"
        >
          View details
        </Link>
      )}
    </div>
  );
};

export default ProjectTimeReportCard;
