import dayjs from 'dayjs';

// @ts-ignore
export const localRandomUuid: string = window.crypto.randomUUID();

/**
 * Converts the snake case to title case
 * ex:
 * input : 'user_good'
 * output : 'User good'
 * @param {string} string
 * @returns {string}
 */
export const convertSnakeCaseToTitleCase = (string: string): string => {
  if (!string) {
    return '';
  }

  return string
    .replace(/_/g, ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * Converts the input number with commas
 * ex:
 * input : '12232'
 * output : '12,232'
 * @param {number} x
 * @returns {string}
 */
export const numberWithCommas = (x: any): string =>
  x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 * coverts number to US Dollar
 * ex:
 * input : '12232'
 * output : '12,232'
 * @param {number} x
 * @returns {string}
 */
export const priceFormatter = (x: any): string => {
  return x || x === 0 || x === '0'
    ? `$${numberWithCommas(Number.parseFloat(`${x}`)?.toFixed(2))}`
    : x;
};

/**
 * Returns the appropriate color for the given status
 * @param {string} status
 * @returns {string}
 */
export const getStatusColor = (status: string): string => {
  const greenStatus = [
    'completed',
    'online',
    'success',
    'paid',
    'processed',
    'sent',
    'active',
    'published',
    'succeeded',
  ];
  const yellowStatus = ['approved', 'draft'];
  const redStatus = ['needs_attention', 'failed', 'fail', 'high'];
  const purpleStatus = ['todo'];
  const cyanStatus = ['in_progress', 'scheduled'];
  const blueStatus = ['working_on_now'];
  const magentaStatus = ['waiting_approval', 'waiting_to_launch'];
  const goldStatus = ['pending', 'paused', 'to_review', 'medium'];
  const greyStatus = ['cancelled', 'inactive', 'deleted'];

  let color = 'blue';

  if (greyStatus.find((el) => el === status)) {
    color = 'grey';
  }

  if (purpleStatus.find((el) => el === status)) {
    color = 'purple';
  }
  if (cyanStatus.find((el) => el === status)) {
    color = 'cyan';
  }
  if (blueStatus.find((el) => el === status)) {
    color = 'blue';
  }
  if (magentaStatus.find((el) => el === status)) {
    color = 'magenta';
  }
  if (goldStatus.find((el) => el === status)) {
    color = 'gold';
  }
  if (greenStatus.find((el) => el === status)) {
    color = 'green';
  }
  if (yellowStatus.find((el) => el === status)) {
    color = 'yellow';
  } else if (redStatus.find((el) => el === status)) {
    color = 'red';
  }
  return color;
};

export function getRequestStatusOptions(withDraft?: boolean) {
  return [
    ...(withDraft ? [{ label: 'Draft', value: 'draft' }] : []),
    { label: 'In preparation', value: 'new' },
    { label: 'Waiting Approval', value: 'waiting_approval' },
    { label: 'Needs attention', value: 'needs_attention' },
    { label: 'Approved', value: 'approved' },
    { label: 'In progress', value: 'in_progress' },
    { label: 'Completed', value: 'completed' },
    { label: 'Cancelled', value: 'cancelled' },
  ];
}

export function getRequestStatusLabel(value: string) {
  return getRequestStatusOptions(true).find((item) => item.value === value)
    ?.label;
}

export const parseDateToUSFormat = (date: any) => {
  if (!date) {
    return '-';
  }

  return dayjs(date).format('MM/DD/YYYY');
};

export const parseDateToMMMDD = (date: any, utc: boolean = false) => {
  if (!date) {
    return undefined;
  }

  const dateObj = utc ? dayjs.utc(date) : dayjs(date);

  return dateObj.format(
    dateObj.isSame(dayjs(), 'year') ? 'MMM DD[th]' : 'MMM DD[th], YY'
  );
};

export const parseDateTimeToUSFormat = (date: any) => {
  if (!date) {
    return '-';
  }

  return dayjs(date).format('MM/DD/YYYY HH:mm');
};

export const getMentionUuidFromNode = (markup: string): string[] => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(markup, 'text/html');
  const uuids: string[] = [];

  doc.querySelectorAll<HTMLSpanElement>('span.mention').forEach((item) => {
    if (item.innerText.trim() && item.dataset.id) {
      uuids.push(item.dataset.id as string);
    }
  });

  return uuids;
};

/**
 * Parses the comment
 * @param {string} comment
 * @returns {string}
 */
export const mentionCommentParser = (comment: string): string => {
  const regex = /@\[(.*?)\]\(.*?\)/g;
  return comment.replaceAll(regex ?? '', '<span class="mention">@$1</span>');
};

/**
 * Check if the given input is valid UUID
 * @param {string} uuid
 * @returns {boolean}
 */
export const checkIfValidUUID = (uuid: string): boolean => {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(uuid);
};

export const getAvatar = (custom?: any) => {
  const user = custom;

  if (!user) {
    return null;
  }

  const nameArr = user?.name?.split(' ') ?? [];
  let name = '';
  const avatar = user?.avatar;

  if (avatar)
    return {
      type: 'img',
      value: avatar,
    };

  nameArr.map((val: string) => {
    name += val.charAt(0);
    return val;
  });
  return {
    type: 'text',
    value: name,
  };
};

/**
 * Return the query string value from the URL
 * @param {string} search
 * @param {string} key
 * @returns {string}
 */
export const getQueryValue = (search: string, key: string) => {
  const query = new URLSearchParams(search);
  return query.get(key);
};

export const urlify = (text = '') => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text?.replace(urlRegex, (url) => {
    return '<a target="blank" href="' + url + '">' + url + '</a>';
  });
};

export const trimUrl = (url: string | undefined) => {
  if (!url) return null;

  if (url.slice(-1) === '/') {
    url = url.slice(0, -1);
  }

  return url;
};

export const convertArrToCommaSeparatedString = (array: any[]): string => {
  let finalString = '';

  array?.forEach((el: any, i: any) => {
    finalString += `${el}${i === array?.length - 1 ? '' : ', '}`;
  });

  return finalString;
};

export const includesInObject = (value: string, object: any) => {
  if (!object || !value) {
    return false;
  }

  let hasSearch = false;

  Object.keys(object).forEach((key: string) => {
    if (typeof object[key] === 'string') {
      if (object[key]?.toLowerCase().includes(value.toLowerCase())) {
        hasSearch = true;
      }
    } else if (typeof object[key] === 'object') {
      return includesInObject(value, object[key]);
    }
  });

  return hasSearch;
};

/**
 * Returns difference between two dates
 * @param {string} startDate
 * @param {string} endDate
 * @returns {number}
 */
export const getDateDifferenceInMinutes = (
  startDate: string,
  endDate = new Date(Date.now())
): number => {
  return dayjs(endDate).diff(startDate) / 60000;
};

export const convertMinToHrsMin = (
  minutes: number,
  showMinZero = true
): string => {
  const value = minutes < 0 ? minutes * -1 : minutes;

  const h = Math.floor(value / 60);
  const m = value % 60;
  const hStr = h < 10 ? `0${h}` : h; // (or alternatively) h = String(h).padStart(2, '0')
  const mStr = m < 10 ? `0${m}` : m; // (or alternatively) m = String(m).padStart(2, '0')
  return `${minutes < 0 ? '-' : ''}${hStr}${
    showMinZero || m > 0 ? `:${mStr}` : ''
  }`;
};

export const removeImage = (html: string | null, url: string) => {
  if (!html) {
    return html;
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  doc.querySelector(`img[src="${url}"]`)?.parentElement?.remove();

  return doc.body.innerHTML;
};

export const checkExternalUrl = (urlString: string) => {
  const url = new URL(urlString);

  return window.location.hostname !== url.hostname;
};

export const getCompletePathUrl = (urlString: string) => {
  const url = new URL(urlString);

  return url.pathname + url.search;
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
