import { AxiosResponse } from 'axios';
import {
  call,
  put,
  select,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';
import {
  getRequests,
  setRequestsData,
  setRequestsError,
  setRequestsFilters,
  setRequestsLoading,
} from './reducer';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import { RootState } from '../index';

const { get } = apiRequests;

function* getRequestsSagaListener(): any {
  const { filters }: RootState['requests'] = yield select(
    (state) => state.requests
  );
  const project: any = yield select((state) => state.projectRetainer.data);

  if (!project) {
    return;
  }

  const searchTerm =
    filters.completed_at_start && filters.completed_at_end
      ? {
          'search_term[0][0][rule]': 'greater_than_or_equals',
          'search_term[0][0][field]': 'completed_at',
          'search_term[0][0][value]': `${filters.completed_at_start} 00:00:00`,
          'search_term[0][1][rule]': 'less_than_or_equals',
          'search_term[0][1][field]': 'completed_at',
          'search_term[0][1][value]': `${filters.completed_at_end} 23:59:59`,
        }
      : {};

  try {
    yield put(setRequestsLoading());

    const res: AxiosResponse = yield call(get, apiRoutes.PROJECT_REQUEST, {
      'sort[by]': 'completed_at',
      'sort[direction]': 'desc',
      'page[size]': 1000,
      'filters[project.uuid][]': project.uuid,
      'filters[status][]': ['completed'],
      with_total_time: true,
      ...searchTerm,
    });

    yield put(setRequestsData(res.data));
  } catch (error) {
    yield put(setRequestsError(error));
  }
}

function* requestsSaga(): Generator<StrictEffect> {
  yield takeLatest(getRequests.type, getRequestsSagaListener);
  yield takeLatest(setRequestsFilters.type, getRequestsSagaListener);
}

export default requestsSaga;
