import { FC, useMemo } from 'react';
import { Form, Typography } from 'antd';
import FormBuilderAnswers from 'src/components/FormBuilder/FormBuilderAnswers';
import { convertFormModelToFormBuilderForm } from 'src/components/Forms';

const { Title, Text } = Typography;

export interface FormBlockProps {
  state: any;
  form?: any;
  disabled?: boolean;
}

const FormBlock: FC<FormBlockProps> = ({ state, form, disabled }) => {
  const formBuilder = useMemo(
    () => state.data && convertFormModelToFormBuilderForm(state.data),
    [state.data]
  );

  return (
    <div className={`form-view w-full`}>
      <div className="show-in-print mb-[20px]">
        <Text className="!text-blue-500">
          Project: {state?.data?.project?.title}
        </Text>

        <br />

        <Text className="!text-blue-500">
          Company : {state?.data?.project?.customer_business_name}
        </Text>
      </div>

      <Title className="mb-[10px]" level={3}>
        {state.data?.title}
      </Title>

      <span>{state.data?.description}</span>

      <div className="mb-[30px]" />

      {formBuilder && (
        <Form
          size="large"
          className="blue-labels default-form"
          form={form}
          layout="vertical"
        >
          <FormBuilderAnswers
            form={form}
            data={formBuilder.form}
            disabled={disabled}
          />
        </Form>
      )}
    </div>
  );
};

export default FormBlock;
