import { FC, useState } from 'react';
import { Button, FormInstance, Modal } from 'antd';
import { PiWarningCircleLight } from 'react-icons/pi';
import { getFormData } from './FormSubmitButton';

interface FormDraftButtonProps {
  form: FormInstance;
  saving?: boolean;
  onDraft: (values: any) => void;
}

const FormDraftButton: FC<FormDraftButtonProps> = ({
  form,
  saving,
  onDraft,
}) => {
  const [openAlert, setOpenAlert] = useState(false);

  return (
    <>
      <Modal
        title={
          <div className="flex items-center gap-2">
            <PiWarningCircleLight className="text-2xl text-yellow-500" />
            Save your request as a draft
          </div>
        }
        open={openAlert}
        onOk={() => {
          setOpenAlert(false);
          getFormData(form)
            .then((values) => onDraft?.(values))
            .catch(() => {
              //
            });
        }}
        onCancel={() => setOpenAlert(false)}
        okText="Save draft"
        cancelText="Go back and edit"
        closable={false}
        maskClosable={false}
      >
        <p className="mb-4">
          You’re about to save a draft that you can edit and submit later.{' '}
          <u>
            <b>Drafts won’t be visible to our team until you submit them.</b>
          </u>{' '}
          Once submitted, we’ll be in touch to get started.
        </p>
      </Modal>

      <Button
        type="default"
        disabled={saving}
        onClick={() => setOpenAlert(true)}
      >
        Save draft
      </Button>
    </>
  );
};

export default FormDraftButton;
