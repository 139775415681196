import React, { Suspense } from 'react';
import { Spin } from 'antd';
import {
  createBrowserRouter,
  RouterProvider,
  ScrollRestoration,
} from 'react-router-dom';
import AuthenticationRoute from 'src/components/AuthenticationRoute';
import ErrorBoundaryRoute from 'src/components/ErrorBoundaryRoute';
import ErrorPage from 'src/404';
import Campaign from 'src/screens/campaigns';
import RedirectTaskPage from 'src/screens/dashboard/RedirectTaskPage';
import Pending from 'src/screens/pending';
import Login from 'src/screens/login';
import Dashboard from 'src/screens/dashboard';
import ResetScreen from 'src/screens/reset';
import Home from 'src/screens/home';
import ClientResource from 'src/screens/client-resources';
import Register from 'src/screens/register';
import SubmitForm from 'src/screens/subbmit-form';
import BookingRoute from 'src/screens/booking/BookingRoute';
import RequestListCreatePage from 'src/screens/request/RequestListCreatePage';
import RequestCreatePage from 'src/screens/request/RequestCreatePage';
import RequestCreateDuplicatePage from 'src/screens/request/RequestCreateDuplicatePage';
import RequestViewPage from 'src/screens/request/RequestViewPage';
import CompanyProfile from 'src/screens/company/CompanyProfile';
import UsagePage from 'src/screens/usage';
import ProjectAssets from 'src/screens/project-assets';
import RedirectProjectPage from 'src/screens/dashboard/RedirectProjectPage';
import Contacts from 'src/screens/contacts';
import Inbox from 'src/screens/inbox';
import FileViewer from 'src/screens/file-viewer';
import Profile from 'src/screens/profile';

const router = createBrowserRouter([
  {
    element: <ErrorBoundaryRoute />,
    children: [
      {
        element: <AuthenticationRoute isAuth />,
        children: [
          {
            path: '/dashboard',
            element: (
              <>
                <ScrollRestoration />
                <Dashboard />
              </>
            ),
          },
          {
            path: '/pending',
            element: (
              <>
                <ScrollRestoration />
                <Pending />
              </>
            ),
          },
          {
            path: '/submit/:uuid',
            element: <SubmitForm />,
          },
          {
            path: '/projects/:uuid',
            element: <RedirectProjectPage />,
          },
          {
            path: '/projects/:uuid/tasks',
            element: <RedirectTaskPage />,
          },
          {
            path: '/projects/:uuid/activity-log',
            element: <RedirectTaskPage />,
          },
          {
            path: '/tasks',
            element: <RedirectTaskPage />,
          },
          {
            path: '/report',
            element: (
              <>
                <ScrollRestoration />
                <Campaign />
              </>
            ),
          },
          {
            path: '/profile',
            element: (
              <>
                <ScrollRestoration />
                <Profile />
              </>
            ),
          },
          {
            path: '/company/profile',
            element: (
              <>
                <ScrollRestoration />
                <CompanyProfile />
              </>
            ),
          },
          {
            path: '/client-resources',
            element: (
              <>
                <ScrollRestoration />
                <ClientResource />
              </>
            ),
          },
          {
            path: '/usage',
            element: (
              <>
                <ScrollRestoration />
                <UsagePage />
              </>
            ),
          },
          {
            path: '/project-assets',
            element: (
              <>
                <ScrollRestoration />
                <ProjectAssets />
              </>
            ),
          },
          {
            path: '/contacts',
            element: (
              <>
                <ScrollRestoration />
                <Contacts />
              </>
            ),
          },
          {
            path: '/inbox',
            element: (
              <>
                <ScrollRestoration />
                <Inbox />
              </>
            ),
          },
          {
            path: '/requests/create',
            element: (
              <>
                <ScrollRestoration />
                <RequestListCreatePage />
              </>
            ),
          },
          {
            path: '/requests/create/:id',
            element: (
              <>
                <ScrollRestoration />
                <RequestCreatePage />
              </>
            ),
          },
          {
            path: '/requests/:uuid/duplicate',
            element: (
              <>
                <ScrollRestoration />
                <RequestCreateDuplicatePage />
              </>
            ),
          },
          {
            path: '/requests/:uuid',
            element: (
              <>
                <ScrollRestoration />
                <RequestViewPage />
              </>
            ),
          },
        ],
      },
      {
        element: <AuthenticationRoute isAuth noLayout />,
        children: [
          {
            path: '/file-viewer/:uuid',
            element: <FileViewer />,
          },
        ],
      },
      {
        element: <AuthenticationRoute isAuth={false} />,
        children: [
          {
            path: '/login',
            element: <Login />,
          },
          {
            path: '/register',
            element: <Register />,
          },
        ],
      },
      {
        element: <AuthenticationRoute isAuth={null} layoutPublic />,
        children: [
          {
            path: '/',
            element: (
              <>
                <ScrollRestoration />
                <Home />
              </>
            ),
          },
        ],
      },
      {
        element: <AuthenticationRoute isAuth={null} noLayout />,
        children: [
          {
            path: '/reset',
            element: (
              <>
                <ScrollRestoration />
                <ResetScreen />
              </>
            ),
          },
        ],
      },
      {
        element: <AuthenticationRoute isAuth={null} />,
        children: [
          {
            path: '/booking',
            element: <BookingRoute />,
          },
          {
            path: '*',
            element: (
              <>
                <ScrollRestoration />
                <ErrorPage />
              </>
            ),
          },
        ],
      },
    ],
  },
]);

const Navigation: React.FC = () => {
  return (
    <Suspense fallback={<Spin />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Navigation;
