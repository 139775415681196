import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { FC, MouseEventHandler } from 'react';

interface NewTaskButtonProps {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const NewRequestButton: FC<NewTaskButtonProps> = ({ onClick }) => {
  return (
    <Link to="/requests/create" onClick={onClick}>
      <Button className="btn-green" type="primary" size="large">
        Create new request
      </Button>
    </Link>
  );
};

export default NewRequestButton;
