import { FC } from 'react';
import FormDiscardButton from './buttons/FormDiscardButton';
import FormDraftButton from './buttons/FormDraftButton';
import FormSavingLoading from './buttons/FormSavingLoading';
import FormSubmitButton from './buttons/FormSubmitButton';
import { FormInstance } from 'antd';

interface FormButtonsProps {
  form: FormInstance;
  saving?: boolean;
  showSavingLoading?: boolean;
  onDiscard?: () => void;
  onDraft?: (values: any) => void;
  onSubmit?: (values: any) => void;
}

const FormButtons: FC<FormButtonsProps> = ({
  form,
  saving,
  showSavingLoading,
  onDiscard,
  onDraft,
  onSubmit,
}) => {
  if (!onDiscard && !onDraft && !onSubmit) {
    return null;
  }

  return (
    <div className="flex flex-wrap justify-between gap-2">
      <div>
        {onDiscard && (
          <FormDiscardButton saving={saving} onDiscard={onDiscard} />
        )}
      </div>

      <div className="space-x-2">
        {!onDraft && showSavingLoading && <FormSavingLoading saving={saving} />}

        {onDraft && (
          <FormDraftButton form={form} saving={saving} onDraft={onDraft} />
        )}

        {onSubmit && (
          <FormSubmitButton form={form} saving={saving} onSubmit={onSubmit} />
        )}
      </div>
    </div>
  );
};

export default FormButtons;
