import { createSlice } from '@reduxjs/toolkit';
import { ApiResponse, InboxRelatedUser } from 'src/types';

export interface RequestsStateType {
  loading: boolean;
  data: ApiResponse<any> | null;
  error: null | string;
  showCompleted: boolean;
}

const initialState: RequestsStateType = {
  loading: false,
  data: null,
  error: null,
  showCompleted: false,
};

export const dashboardRequestsSlice = createSlice({
  name: 'dashboardRequests',
  initialState,
  reducers: {
    getDashboardRequests: (
      state,
      action: { payload: Record<string, any>; type: string }
    ) => {},
    setDashboardRequestsLoading: (state) => ({ ...state, loading: true }),
    setDashboardRequestsData: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    clearDashboardRequestsData: () => initialState,
    setDashboardRequestsError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    setDashboardRequestCompleted: (
      state,
      action: { payload: boolean; type: string }
    ) => ({
      ...state,
      showCompleted: action.payload,
    }),
    updateDashboardRequestCommentRelatedUserMention: (
      state,
      {
        payload,
      }: {
        payload: {
          uuid: string;
          newValues: Partial<InboxRelatedUser>;
          user: any;
        };
        type: string;
      }
    ) =>
      state.data
        ? {
            ...state,
            data: {
              ...state.data,
              data: state.data.data.map((item) =>
                item.uuid === payload.uuid && item.comment_latest
                  ? {
                      ...item,
                      comment_latest: {
                        ...item.comment_latest,
                        related_users: item.comment_latest.related_users.map(
                          (relatedUser: any) =>
                            relatedUser.uuid !== payload.user.uuid
                              ? relatedUser
                              : { ...relatedUser, ...payload.newValues }
                        ),
                      },
                    }
                  : item
              ),
            },
          }
        : state,
  },
});

export const {
  setDashboardRequestsData,
  setDashboardRequestsError,
  setDashboardRequestsLoading,
  getDashboardRequests,
  clearDashboardRequestsData,
  setDashboardRequestCompleted,
  updateDashboardRequestCommentRelatedUserMention,
} = dashboardRequestsSlice.actions;

export default dashboardRequestsSlice.reducer;
