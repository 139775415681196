import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RequestsListCard from 'src/components/RequestsListCard';
import { RootState } from 'src/store';
import {
  getDashboardRequests,
  clearDashboardRequestsData,
  setDashboardRequestCompleted,
} from 'src/store/dashboardRequests/reducer';
import { useAppTour } from 'src/context/appTourContext';
import useWebSocket from 'src/utils/useWebSocket';

export default function Dashboard() {
  const dashboardRequests = useSelector(
    (globalState: RootState) => globalState.dashboardRequests
  );
  const projectRetainer = useSelector(
    (globalState: RootState) => globalState.projectRetainer
  );

  const projectRetainerUuid = projectRetainer.data?.uuid;

  const dispatch = useDispatch();
  const { setStepTarget } = useAppTour();

  const fetchRequests = useCallback(() => {
    if (projectRetainerUuid) {
      dispatch(
        getDashboardRequests({
          'filters[project.uuid][]': projectRetainerUuid,
        })
      );
    }
  }, [dispatch, projectRetainerUuid]);

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  useWebSocket({
    channelName: 'model.changes',
    listen: {
      event: '.app.models.projects.request',
      callback: async () => {
        fetchRequests();
      },
    },
  });

  return (
    <div ref={(node) => setStepTarget(6, node)}>
      <RequestsListCard
        loading={
          (projectRetainer.loading && !projectRetainerUuid) ||
          dashboardRequests.loading
        }
        data={dashboardRequests.data?.data}
        showCompletedValue={dashboardRequests.showCompleted}
        onShowAll={(checked) => {
          dispatch(clearDashboardRequestsData());
          dispatch(setDashboardRequestCompleted(checked));
          fetchRequests();
        }}
      />
    </div>
  );
}
