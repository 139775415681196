import { DatePicker, Empty, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect } from 'react';
import { IoFolderOpenOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import Box from 'src/components/Box';
import RequestItem from 'src/components/RequestItem';
import { RootState } from 'src/store';
import { convertMinToHrsMin } from 'src/utils/stringHelpers';
import {
  clearRequestsData,
  getRequests,
  setRequestsFilters,
} from 'src/store/requests/reducer';

const { RangePicker } = DatePicker;

const UsagePage: FC = () => {
  const {
    loading: requestsLoading,
    data: requests,
    filters,
  } = useSelector((globalState: RootState) => globalState.requests);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRequests());
  }, [dispatch]);

  return requestsLoading || requests ? (
    <>
      <div className="mb-2 flex flex-wrap justify-between gap-2 px-1 text-blue-600">
        <div className="font-bold">
          <IoFolderOpenOutline className="mr-2 inline-block text-2xl" />

          <span className="align-middle">Usage</span>
        </div>

        <div>
          <RangePicker
            className="!mr-4"
            value={[
              filters.completed_at_start
                ? dayjs(filters.completed_at_start)
                : null,
              filters.completed_at_end ? dayjs(filters.completed_at_end) : null,
            ]}
            format="MM/DD/YYYY"
            presets={[
              {
                label: 'Last 30 days',
                value: [dayjs().subtract(30, 'd'), dayjs()],
              },
              {
                label: 'This month',
                value: [dayjs().startOf('month'), dayjs().endOf('month')],
              },
              {
                label: 'Last month',
                value: [
                  dayjs().subtract(1, 'month').startOf('month'),
                  dayjs().subtract(1, 'month').endOf('month'),
                ],
              },
              {
                label: 'Last 90 days',
                value: [dayjs().subtract(90, 'days').startOf('day'), dayjs()],
              },
            ]}
            disabledDate={(value) =>
              value.isBefore(dayjs().subtract(90, 'days').startOf('day'))
            }
            onChange={(dates) => {
              const newDates =
                dates?.[0] && dates?.[1]
                  ? [
                      dates[0].format('YYYY-MM-DD'),
                      dates[1].format('YYYY-MM-DD'),
                    ]
                  : null;

              dispatch(clearRequestsData());

              dispatch(
                setRequestsFilters(
                  newDates
                    ? {
                        completed_at_start: newDates[0],
                        completed_at_end: newDates[1],
                      }
                    : null
                )
              );
            }}
          />

          <span className="whitespace-nowrap">
            Total time:{' '}
            {convertMinToHrsMin(parseInt(requests?.total_time ?? '0', 10))}
          </span>
        </div>
      </div>

      <div>
        {requestsLoading && !requests ? (
          <Skeleton className="py-4" />
        ) : (
          <div className="space-y-3">
            {requests?.data?.map((request) => {
              return <RequestItem key={request.uuid} request={request} />;
            })}

            {!requests?.data?.length ? <Empty /> : undefined}
          </div>
        )}
      </div>
    </>
  ) : (
    <Box>
      <Empty description={<span className="text-gray-400">No project</span>} />
    </Box>
  );
};

export default UsagePage;
