/* eslint-disable @typescript-eslint/dot-notation */
import { Col, Dropdown, Empty, Input, Row, Space, Typography } from 'antd';
import SimpleBar from 'simplebar-react';

import { FC, useEffect, useState } from 'react';

import {
  CloseCircleOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import axios, { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { includesInObject } from 'src/utils/stringHelpers';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import StatusTag from '../StatusTag';
import { debounceAsync } from 'src/utils/debounce';

interface GlobalSearchProps {
  dropdownRenderClassName?: string;
  onLinkClick?: any;
}

const { Text } = Typography;

const debounceGlobalSearch = debounceAsync<AxiosResponse>((params: any) => {
  return apiRequests.get(apiRoutes.GLOBAL_SEARCH, params);
}, 400);

const GlobalSearch: FC<GlobalSearchProps> = ({
  dropdownRenderClassName,
  onLinkClick,
}) => {
  const [state, setState] = useState({
    loading: false,
    data: {} as Record<string, any>,
    search: null as any,
    open: false,
  });

  const { loading, data, search, open } = state;

  const filteredData: Record<string, any> = {};

  Object.keys(data).forEach((el) => {
    const newData = data[el].filter((item: any) => {
      return includesInObject(search, item);
    });

    if (el === 'forms' || el === 'projects' || el === 'requests') {
      filteredData[el] = newData;
    }
  });

  const hasData = !!Object.keys(data).find((el) => data[el].length !== 0);

  const navigate = useNavigate();

  const toggleOpen = () => {
    setState((prevState) => ({ ...prevState, open: !prevState.open }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setState((prevState: any) => ({ ...prevState, loading: true }));

        const params: any = {};
        params['search_term'] = search;

        const response = await debounceGlobalSearch(params);

        setState((prevState: any) => ({
          ...prevState,
          loading: false,
          data: response.data.data,
        }));
      } catch (error) {
        if (!axios.isCancel(error)) {
          asyncErrorHandler(error);
          setState((prevState: any) => ({ ...prevState, loading: false }));
        }
      }
    };

    if (search) {
      fetchData();
    } else {
      setState((prevState: any) => ({
        ...prevState,
        loading: false,
        data: {},
      }));
    }
  }, [search]);

  return (
    <Dropdown
      open={open && !!search}
      dropdownRender={() => (
        <div
          className={`rounded-md bg-white shadow-xl ${dropdownRenderClassName}`}
        >
          {!hasData && !loading && (
            <div style={{ padding: 30 }}>
              <Empty
                description={
                  <span className="text-gray-400">No data found</span>
                }
              />
            </div>
          )}

          {hasData && (
            <>
              {/* @ts-ignore */}
              <SimpleBar style={{ maxHeight: 400 }}>
                {Object.keys(filteredData).map((type) => {
                  const parsedData = filteredData[type];

                  if (parsedData.length === 0) {
                    return null;
                  }

                  return (
                    <div className="global-search-item" key={type}>
                      {parsedData.map((item: any, key: any) => {
                        return (
                          <div
                            onClick={() => {
                              if (type === 'projects') {
                                navigate(`/projects/${item.uuid}`);
                              } else if (type === 'tasks') {
                                navigate(`?task_id=${item.task_uuid}`, {
                                  preventScrollReset: true,
                                });
                              } else if (type === 'forms') {
                                navigate(`/pending?form_id=${item.uuid}`);
                              }

                              if (onLinkClick) {
                                onLinkClick();
                              }
                            }}
                            className="global-search-data"
                            key={key}
                          >
                            <Row
                              wrap={false}
                              justify="space-between"
                              gutter={10}
                            >
                              <Col
                                span={item.status || item.task_status ? 18 : 24}
                              >
                                {type === 'requests' && (
                                  <div className="text-xs text-gray-400">
                                    {item.title}
                                  </div>
                                )}

                                <Text className="font-bold" ellipsis>
                                  {item.subject ??
                                    item.title ??
                                    item.name ??
                                    item.task_title}
                                </Text>
                              </Col>

                              {(item.status || item.task_status) && (
                                <Col>
                                  <StatusTag
                                    value={item.status ?? item.task_status}
                                  />
                                </Col>
                              )}
                            </Row>

                            {(type === 'projects' || type === 'tasks') && (
                              <Row>
                                <Col span={24}>
                                  <Text
                                    style={{ fontSize: 12 }}
                                    className="primary"
                                    ellipsis
                                  >
                                    {item.customer_business_name}
                                  </Text>
                                </Col>
                              </Row>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </SimpleBar>
            </>
          )}
        </div>
      )}
    >
      <div>
        <Input
          placeholder="Search for anything"
          value={search}
          onFocus={toggleOpen}
          onBlur={() => {
            setTimeout(() => toggleOpen(), 300);
          }}
          onChange={(e) => {
            setState((prevState) => ({ ...prevState, search: e.target.value }));
          }}
          size="large"
          className="!w-full !rounded-md"
          suffix={
            <Space>
              <Loading3QuartersOutlined
                style={{ visibility: loading ? 'visible' : 'hidden' }}
                spin
                size={20}
              />

              {search && (
                <CloseCircleOutlined
                  className="pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setState((prevState) => ({
                      ...prevState,
                      search: undefined,
                    }));
                  }}
                />
              )}
            </Space>
          }
        />
      </div>
    </Dropdown>
  );
};

export default GlobalSearch;
