import { FC, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import Layout from 'src/components/Layout';
import CheckMaintenance from 'src/components/Maintenance/CheckMaintenance';
import LayoutPublic from 'src/components/LayoutPublic';
import LoadingPage from 'src/components/LoadingPage';
import { RootState } from 'src/store';
import { loadUser } from 'src/store/auth/reducer';
import { getProjectRetainer } from 'src/store/projectRetainer/reducer';
import { setSentryUser } from 'src/sentry';
import AppTourProvider from 'src/context/appTourContext';

interface AuthenticationRouteProps {
  isAuth?: boolean | null;
  allowInMaintenance?: boolean;
  noLayout?: boolean;
  layoutPublic?: boolean;
}

const AuthenticationRoute: FC<AuthenticationRouteProps> = ({
  isAuth = null,
  allowInMaintenance = false,
  noLayout = false,
  layoutPublic = false,
}) => {
  const { user, loading } = useSelector(
    (globalState: RootState) => globalState.auth
  );
  const projectRetainer = useSelector(
    (globalState: RootState) => globalState.projectRetainer
  );

  const [searchParams] = useSearchParams();

  const isMountedRef = useRef(false);
  const dispatch = useDispatch();

  if (!isMountedRef.current) {
    isMountedRef.current = true;
    dispatch(loadUser());
    dispatch(getProjectRetainer());
  }

  if (
    (loading && !user) ||
    (projectRetainer.loading && !projectRetainer.data)
  ) {
    return <LoadingPage />;
  }

  if (user && isAuth === false) {
    return <Navigate replace to="/dashboard" />;
  }

  if (!user && isAuth === true) {
    return <Navigate replace to="/login" />;
  }

  if (searchParams.has('task_id')) {
    if (searchParams.get('task_id') === 'create') {
      return <Navigate to="/requests/create" />;
    }

    return <Navigate to="/dashboard" />;
  }

  setSentryUser(user);

  if (layoutPublic) {
    return (
      <CheckMaintenance user={user} allowInMaintenance={allowInMaintenance}>
        <LayoutPublic>
          <Outlet />
        </LayoutPublic>
      </CheckMaintenance>
    );
  }

  if (!user || noLayout) {
    return (
      <CheckMaintenance user={user} allowInMaintenance={allowInMaintenance}>
        <Outlet />
      </CheckMaintenance>
    );
  }

  return (
    <CheckMaintenance user={user} allowInMaintenance={allowInMaintenance}>
      <AppTourProvider>
        <Layout key={user.contact?.customer?.uuid}>
          <Outlet />
        </Layout>
      </AppTourProvider>
    </CheckMaintenance>
  );
};

export default AuthenticationRoute;
