import { FC, ReactNode, useRef } from 'react';
import dayjs from 'dayjs';
import { Form } from 'antd';
import { Rule } from 'antd/es/form';
import { FormBuilderFormData } from 'src/types/formBuilder';
import { mapModelToUploadFileApi } from 'src/utils/arrayHelpers';

interface FormItemProps {
  data: FormBuilderFormData;
  children: ReactNode;
}

const FormItem: FC<FormItemProps> = ({ data, children }) => {
  const answerRef = useRef<any>();
  const parsedAnswerRef = useRef<any>();

  if ('answer' in data && answerRef.current !== data.answer) {
    answerRef.current = data.answer;

    parsedAnswerRef.current = data.answer;

    if (data.answer) {
      if (data.type === 'date') {
        parsedAnswerRef.current =
          data.mode === 'time' ? dayjs(data.answer) : dayjs.utc(data.answer);
      }

      if (data.type === 'upload' && Array.isArray(data.answer)) {
        parsedAnswerRef.current = mapModelToUploadFileApi(data.answer);
      }
    }
  }

  const rules: Rule[] | undefined =
    data.type === 'upload'
      ? [
          {
            validator(_, value) {
              if (
                !value ||
                !Array.isArray(value) ||
                !value.find((item) => item.status === 'uploading')
              ) {
                return Promise.resolve();
              }

              return Promise.reject(
                new Error('Wait for all uploads to finish')
              );
            },
          },
        ]
      : [];

  if (data.type === 'header' || data.type === 'formRef') {
    return <>{children}</>;
  }

  return (
    <Form.Item
      name={data.name}
      label={data.label}
      className={data.optionalLabel ? 'ant-optional-label' : undefined}
      style={{ margin: data.marginX ? '-8px 18px 16px 18px' : '0 0 16px' }}
      initialValue={parsedAnswerRef.current}
      rules={[
        ({ getFieldValue }) => {
          if (
            (!('options' in data) || data.options.length > 1) &&
            data.type !== 'upload' &&
            !data.optionalLabel &&
            getFieldValue('alert_submitting')
          ) {
            return {
              required: true,
              message:
                "This field is optional, but make sure you don't want to fill in",
            };
          }

          if (data.submitRequired && getFieldValue('submitting')) {
            return {
              required: true,
              message: 'This field is required when submitting project brief',
            };
          }

          return {
            required: data.required,
            message: 'This field is required',
          };
        },
        ...rules,
      ]}
    >
      {children}
    </Form.Item>
  );
};

export default FormItem;
