import { FC, useEffect } from 'react';
import { Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Forms from 'src/components/Forms';
import AlertPendingCard from 'src/components/AlertPendingCard';
import { getPendingForms } from 'src/store/pendingForms/reducer';
import { RootState } from 'src/store';

const Pending: FC = () => {
  const pendingForms = useSelector(
    (globalState: RootState) => globalState.pendingForms
  );

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPendingForms());
  }, [dispatch]);

  useEffect(() => {
    if (
      !pendingForms.loading &&
      pendingForms.data &&
      !pendingForms.data.data.length
    ) {
      navigate('/dashboard', { replace: true });
    }
  }, [pendingForms.loading, pendingForms.data, navigate]);

  const showLoading = pendingForms.loading && !pendingForms.data;

  return (
    <>
      {pendingForms.data?.data?.length ? <AlertPendingCard /> : undefined}

      <div className="mt-6">
        {showLoading ? <Skeleton className="pt-2" /> : undefined}

        {!showLoading && pendingForms.data?.data?.length ? (
          <Forms data={pendingForms.data.data} />
        ) : undefined}
      </div>
    </>
  );
};

export default Pending;
